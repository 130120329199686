import { useState } from "react";
import styles from "./AdminPage.module.scss";
import cl from "classnames";
import TasksTab from "../TasksTab/TasksTab";
import { Broadcast } from "../Broadcast/Broadcast";
import { Promocode } from "../Promocode/Promocode";
import { CreateVote } from "../CreateVote/CreateVote";

const AdminPage = () => {
	const [selectedTab, setSelectedTab] = useState<
		"EDIT TASKS" | "BROADCAST TASK" | "PROMO CODE" | "CREATE VOTE"
	>("EDIT TASKS");

	return (
		<div className={styles.content}>
			<div className={styles.tabs}>
				<div
					className={cl(styles.tab, {
						[styles.tabActive]: selectedTab === "EDIT TASKS",
					})}
					onClick={() => setSelectedTab("EDIT TASKS")}
				>
					<button>EDIT TASKS</button>
				</div>
				<div
					className={cl(styles.tab, {
						[styles.tabActive]: selectedTab === "BROADCAST TASK",
					})}
					onClick={() => setSelectedTab("BROADCAST TASK")}
				>
					<button>BROADCAST TASK</button>
				</div>
				<div
					className={cl(styles.tab, {
						[styles.tabActive]: selectedTab === "PROMO CODE",
					})}
					onClick={() => setSelectedTab("PROMO CODE")}
				>
					<button>PROMO CODE</button>
				</div>{" "}
				<div
					className={cl(styles.tab, {
						[styles.tabActive]: selectedTab === "CREATE VOTE",
					})}
					onClick={() => setSelectedTab("CREATE VOTE")}
				>
					<button>CREATE VOTE</button>
				</div>
			</div>
			<div className={styles.mainContent}>
				{selectedTab === "EDIT TASKS" && <TasksTab />}
				{selectedTab === "BROADCAST TASK" && <Broadcast />}
				{selectedTab === "PROMO CODE" && <Promocode />}
				{selectedTab === "CREATE VOTE" && <CreateVote />}
			</div>
		</div>
	);
};

export default AdminPage;
