import { AxiosResponse } from "axios";
import agent from "../../../app/api/axios";
import { IDescription } from "../types/admin";

export const fetchGetTasks = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/all_tasks`);
};

export const fetchGetLeaderboard = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/leaderboard`);
};

export const fetchGetPromocodes = (
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/all_promocodes`, {
		password: password,
	});
};

export const fetchCompleteTask = (
	taskId: number
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/task`, {
		task: taskId,
	});
};

export const fetchCheckAppPassword = (
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/check_common_password`, {
		password: password,
	});
};

export const fetchAddTask = (
	name: string,
	description: IDescription,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	password: string,
	secretPhrase: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/add_task`, {
		name: name,
		description: JSON.stringify(description),
		reward: reward,
		active: active,
		start: start,
		end: end,
		password: password,
		secretPhrase: secretPhrase,
	});
};

export const fetchUpdateTask = (
	name: string,
	description: IDescription,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	password: string,
	id: number,
	secretPhrase: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/update_task`, {
		name: name,
		description: JSON.stringify(description),
		reward: reward,
		active: active,
		start: start,
		end: end,
		password: password,
		id: id,
		secretPhrase: secretPhrase,
	});
};

export const fetchBroadcast = (
	message: { en: string; ru: string },
	password: string,
	buttonText?: { en?: string; ru?: string },
	imageUrlBase64?: string,
	inlineButton?: { text: string; url: string }[]
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/broadcast`, {
		message: message,
		password: password,
		buttonText: buttonText,
		imageUrlBase64: imageUrlBase64,
		inlineButton: inlineButton,
	});
};

export const fetchUpdatePromocode = (
	value: string,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	id: number,
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/update_promocode`, {
		value: value,
		reward: reward,
		active: active,
		start: start,
		end: end,
		id: id,
		password: password,
	});
};

export const fetchAddPromocode = (
	value: string,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/add_promocode`, {
		value: value,
		reward: reward,
		active: active,
		start: start,
		end: end,
		password: password,
	});
};

export const fetchAddVoting = (
	password: string,
	first_option: {
		description: string;
		image: string;
	},
	second_option: {
		description: string;
		image: string;
	},
	start: string,
	end: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/add_voting`, {
		password: password,
		first_option: first_option,
		second_option: second_option,
		start: start,
		end: end,
	});
};

export const fetchUpdateVoting = (
	password: string,
	first_option: {
		description: string;
		image: string;
	},
	second_option: {
		description: string;
		image: string;
	},
	start: string,
	end: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/update_voting`, {
		password: password,
		id: 1,
		first_option: first_option,
		second_option: second_option,
		start: start,
		end: end,
	});
};

export const fetchGetVoting = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/voting`);
};
